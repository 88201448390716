import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { Div, FlexCenter, HeaderTh, MyPaginate, PageTitle, StyledTable } from '../../style';
import { EFlowType, EShippingStatus } from '../../model/enum';
import { IFilterSendFile, ShippingDetailSdor } from '../../model';
import { checkFiltersSdor, getNegativeRandom, removeUnderscore } from '../../utils';

import FilterSdor from '../Filter/FilterSdor';
import GenericError from '../GenericError/GenericError';
import { ITEMSPERPAGE } from '../CustomTable/UploadTable';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import Sdor from './Sdor';
import { Table } from 'react-bootstrap';
import UploadTableSdoR from '../CustomTable/UploadTableSdoR';
import { displayMsgErrors } from '../Toast/CustomToast';
import { get } from 'lodash';
import { getAllShippingsSdor } from '../../api/fetch';
import { initialDataSendFile } from '../../utils/constants';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

interface IShippingContextSdor {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    freezedShippings: ShippingDetailSdor[];
    shippings: ShippingDetailSdor[];
    setShippings: Dispatch<SetStateAction<ShippingDetailSdor[]>>;
    itemOffset: number;
    filter: IFilterSendFile;
    setFilter: Dispatch<SetStateAction<IFilterSendFile>>;
    setItemOffset: Dispatch<SetStateAction<number>>;
    setFreezedShippings: Dispatch<SetStateAction<ShippingDetailSdor[]>>;
}
export const ShippingContextSdor = createContext<IShippingContextSdor>({
    filter: initialDataSendFile,
    setFilter: () => {},
    itemOffset: 0,
    setItemOffset: () => {},
    page: 0,
    setPage: () => {},
    freezedShippings: [],
    shippings: [],
    setShippings: () => {},
    setFreezedShippings: () => {},
});

interface ISendFilesSdorProps {}
const SendFilesSdor = (props: ISendFilesSdorProps) => {
    const { t } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const { instance } = useMsal();

    const { shippings, isLoading, isError, isValidating, mutate } = getAllShippingsSdor(loggedUser.userId);
    const { pendingShipping, permissions } = shippings;
    const [refresh, setRefresh] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<ShippingDetailSdor[]>(pendingShipping);
    const [freezedFiles, setFreezedFiles] = useState<ShippingDetailSdor[]>(pendingShipping);
    const [displayedToast, setDisplayedToast] = useState<boolean>(false);

    const [filter, setFilter] = useState<IFilterSendFile>(initialDataSendFile);
    const [itemOffset, setItemOffset] = useState(0);
    const [page, setPage] = useState(0);

    const lastName = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.family_name', 'username'));
    const name = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.given_name', 'username'));

    const columns = [
        { name: t('sdoOverview.clmDateUp'), id: 0 },
        { name: t('sdoOverview.fileNames'), id: 1 },
        { name: t('sdoOverview.clmNameShip'), id: 2 },
        { name: t('sdoOverview.clmUsername'), id: 3 },
        { name: t('generic.totalRows'), id: 5 },
        { name: t('generic.wrongRows'), id: 6 },
        { name: t('sdoOverview.clmErrRic'), id: 7 },
        { name: t('sdoOverview.clmLog'), id: 8 },
        { name: t('sdoOverview.clmStaSp'), id: 9 },
        { name: t('sdoOverview.clmDrg'), id: 10 },
        { name: '', id: 11 },
        { name: '', id: 12 },
    ];

    const refreshShippings = () => {
        mutate();
    };

    const pageCount = Math.ceil(uploadedFiles.length / ITEMSPERPAGE);

    const updateCall = async (files: string[]) => {
        let data = [
            {
                userName: `${name.charAt(0) + lastName}`,
                receiptDate: new Date().toString(),
                id: getNegativeRandom(),
                fileNameRiab: files[0],
                fileRiabPath: '',
                logFileName: '',
                logFilePath: '',
                processingDate: undefined,
                error: false,
                queued: true,
                shippingStatus: 0,
                hospitalizationsCount: 0,
                rehabErrors: 0,
                totalRehabRows: 0,
                successRatio: 0,
                drg: false,
            } as ShippingDetailSdor,
            ...uploadedFiles,
        ];
        setUploadedFiles(data);
        setFreezedFiles([
            {
                userName: `${name.charAt(0) + lastName}`,
                receiptDate: new Date().toString(),
                id: getNegativeRandom(),
                fileNameRiab: files[0],
                fileRiabPath: '',
                logFileName: '',
                logFilePath: '',
                processingDate: undefined,
                error: false,
                queued: true,
                shippingStatus: 0,
                hospitalizationsCount: 0,
                rehabErrors: 0,
                totalRehabRows: 0,
                drg: false,
            } as ShippingDetailSdor,
            ...freezedFiles,
        ]);
        setPage(0);
        setItemOffset(0);
        setFilter(initialDataSendFile);
        setRefresh(true);
    };

    useEffect(() => {
        if (freezedFiles.length > 0 && isLoading === false) {
            const filteredSummaries = freezedFiles
                .map(item => ({
                    id: item.id,
                    shippingStatus: item.shippingStatus,
                    fileErrorsSummary: item.fileErrorsSummary,
                }))
                .filter(item => item.fileErrorsSummary !== null && item.fileErrorsSummary !== undefined && item.shippingStatus === EShippingStatus.CheckControlsPerformed);

            if (filteredSummaries.length > 0 && !displayedToast) {
                displayMsgErrors(filteredSummaries, EFlowType.Sdor);
                setDisplayedToast(true);
            }
        }
    }, [freezedFiles, isLoading, displayedToast]);

    useEffect(() => {
        if (!isValidating && !!pendingShipping) {
            if (!!filter.date || !!filter.sped || !!filter.userName || (!!filter.status && filter.status > 0)) {
                setUploadedFiles(pendingShipping.filter(data => checkFiltersSdor(data, filter.sped, filter.date, filter.status, filter.userName)));
            } else {
                setUploadedFiles([...pendingShipping]);
                setFreezedFiles([...pendingShipping]);
            }
        }
    }, [isValidating]);

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * ITEMSPERPAGE) % uploadedFiles.length;
        setItemOffset(newOffset);
        setPage(event.selected);
    };
    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <ShippingContextSdor.Provider
            value={{
                page,
                setPage,
                itemOffset,
                setItemOffset,
                setFreezedShippings: setFreezedFiles,
                freezedShippings: freezedFiles,
                shippings: uploadedFiles,
                setShippings: setUploadedFiles,
                filter,
                setFilter,
            }}>
            {!!permissions && permissions.canUpload && <Sdor updateCall={updateCall} />}
            {isError && <GenericError />}
            <PageTitle>{t('generic.sendFileTitle')}</PageTitle>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {!refresh && <FilterSdor />}
                    {freezedFiles.length > 0 && (
                        <StyledTable>
                            <Table size='sm' responsive='xl'>
                                <thead>
                                    <tr>
                                        {columns.map(data => (
                                            <HeaderTh key={data.id} className={'table-dark'}>
                                                {data.name}
                                            </HeaderTh>
                                        ))}
                                    </tr>
                                </thead>
                                <UploadTableSdoR refreshShippings={refreshShippings} permissions={permissions} />
                            </Table>
                        </StyledTable>
                    )}
                    <FlexCenter>{!freezedFiles.length && <p>{t('generic.noShipping')}</p>}</FlexCenter>
                    <Div>
                        {pageCount > 0 && (
                            <MyPaginate
                                breakLabel='...'
                                nextLabel='>'
                                forcePage={page}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel={'<'}
                                renderOnZeroPageCount={null}
                            />
                        )}
                    </Div>
                </>
            )}
        </ShippingContextSdor.Provider>
    );
};

export default SendFilesSdor;
