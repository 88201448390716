import { AlertError, AlertSuccess } from '../../componets/Alert/Alert';
import { Button, Table } from 'react-bootstrap';
import i18n, { ELanguages } from '../../localization';
import { useCallback, useContext, useState } from 'react';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import CustomModal from '../../componets/Modal/CustomModal';
import CustomSvg from '../../componets/Svg/CustomSvg';
import GenericError from '../../componets/GenericError/GenericError';
import { IconBtn } from '../../componets/Buttons/Buttons';
import Loading from '../../componets/Loading/Loading';
import { LoggedUserContext } from '../../App';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../api/fetch';

const IconBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 1rem;
`;
const StyledTable = styled(Table)`
    table-layout: fixed;
    width: 100%;
`;

const StyledTr = styled.tr`
    max-height: 5rem;
`;

const StyledTd = styled.td`
    max-height: 5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const NameTd = styled(StyledTd)`
    width: 15%;
`;

const SurnameTd = styled(StyledTd)`
    width: 15%;
`;

const EmailTd = styled(StyledTd)`
    width: 20%;
`;

const RoleTd = styled(StyledTd)`
    width: 15%;
`;

const StructuresTd = styled(StyledTd)`
    width: 25%;
`;

const ActionTd = styled(StyledTd)`
    width: 15%;
    text-align: right;
    justify-content: center;
    align-items: center;
`;

const StyledTh = styled.th`
    max-height: 5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 15%;
`;

const NameTh = styled(StyledTh)`
    width: 15%;
`;

const SurnameTh = styled(StyledTh)`
    width: 15%;
`;

const EmailTh = styled(StyledTh)`
    width: 20%;
`;

const RoleTh = styled(StyledTh)`
    width: 15%;
`;

const StructuresTh = styled(StyledTh)`
    width: 30%;
`;

const ActionTh = styled(StyledTh)`
    width: 15%;
    text-align: right;
    vertical-align: middle;
`;

interface IUsersProps {}
const Users = (props: IUsersProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loggedUser } = useContext(LoggedUserContext);

    const { users, isLoading, isError, isValidating, mutate } = useUsers();
    const { trigger: triggerDelete } = useSWRMutation(`User/Delete`, httpClient.postRequest);

    const [show, setShow] = useState<{ name: string; userId: number | undefined }>({ name: '', userId: undefined });
    const [loadingDelete, setLoadingDelete] = useState(false);

    const deleteUser = useCallback(async () => {
        setLoadingDelete(true);
        try {
            if (show.userId === loggedUser.userId) {
            }
            await triggerDelete({ userIdToDelete: show.userId, currentUserId: loggedUser.userId });
            mutate();
            setShow({ userId: undefined, name: '' });

            AlertSuccess(t('users.editOk'));
        } catch (error) {
            AlertError(t('users.editKo'));
        } finally {
            setLoadingDelete(false);
        }
    }, [show.userId]);

    const loadingFetchData = isLoading || isValidating;

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.users'), url: route.users }]} />
            <Button type='button' className='align-self-end' onClick={() => navigate(route.new)} variant='primary'>
                {t('users.new')}
            </Button>
            {loadingFetchData && <Loading />}
            {isError && <GenericError />}
            {!loadingFetchData && users && users.length > 0 && (
                <StyledTable size='xl' responsive='xl'>
                    <thead>
                        <StyledTr>
                            <SurnameTh>{t('users.clmSurname')}</SurnameTh>
                            <NameTh>{t('users.clmName')}</NameTh>
                            <EmailTh>{t('users.clmEmail')}</EmailTh>
                            <RoleTh>{t('users.clmRole')}</RoleTh>
                            <StructuresTh>{t('users.clmStruc')}</StructuresTh>
                            <ActionTh></ActionTh>
                        </StyledTr>
                    </thead>
                    <tbody>
                        {users.map(data => (
                            <StyledTr key={data.userId}>
                                <SurnameTd>{data.userSurname}</SurnameTd>
                                <NameTd>{data.userName}</NameTd>
                                <EmailTd>{data.userEmail}</EmailTd>
                                <RoleTd>{i18n.language === ELanguages.it ? data.userRoleIt : data.userRoleDe}</RoleTd>
                                <StructuresTd>
                                    {data.userRoleIt === 'SIAG' || data.userRoleDe === 'SIAG'
                                        ? t('users.allStructures')
                                        : data.structures.map(s => (i18n.language === ELanguages.it ? s.structureNameIt : s.structureNameDe)).join(',')}
                                </StructuresTd>
                                <ActionTd>
                                    <IconBtnContainer>
                                        <IconBtn withOutline onClick={() => navigate(`${data.userId}`)}>
                                            <CustomSvg iconName='it-pencil' className='icon icon-primary' />
                                        </IconBtn>
                                        <IconBtn borderProps='1px solid #995c00' withOutline onClick={() => setShow({ userId: data.userId, name: `${data.userName} ${data.userSurname}` })}>
                                            <CustomSvg iconName='it-delete' className='icon icon-warning' />
                                        </IconBtn>
                                    </IconBtnContainer>
                                </ActionTd>
                            </StyledTr>
                        ))}
                    </tbody>
                </StyledTable>
            )}
            <CustomModal
                key={`cancelModal ${show.userId}`}
                title={t('modals.deleteTitle')}
                description={`${t('modals.deleteUser', { name: show.name })}`}
                handleConfirm={deleteUser}
                disabled={loadingDelete}
                show={!!show && !!show.userId}
                handleClose={() => {
                    setShow({ userId: undefined, name: '' });
                }}
            />
        </Container>
    );
};

export default Users;
