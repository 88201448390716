import { Container, RightTd, TableRow } from '../../style';
import { Fragment, useContext } from 'react';
import { computeTotalsAndDifference, getCurrentMonthAndYear } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelFim from '../ExportExcel/ExportExcelFim';
import GenericError from '../GenericError/GenericError';
import { IOverviewFim } from '../../model';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { t } from 'i18next';
import { useFimOverview } from '../../api/fetch';

interface IOverviewFimProps {}
const OverviewFim = (props: IOverviewFimProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useFimOverview(loggedUser.userId);

    const findRecentYear = (data: IOverviewFim[]): string => {
        if (!!data && data.length > 0) {
            let maxYear = data[0].year;

            data.forEach(item => {
                const itemYear = parseInt(item.year);
                const currentMaxYear = parseInt(maxYear);

                if (!isNaN(itemYear) && (isNaN(currentMaxYear) || itemYear > currentMaxYear)) {
                    maxYear = item.year;
                }
            });

            return maxYear;
        }
        return '';
    };

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverviewFim[]);

    const sortByStructureCode = (arr: IOverviewFim[]) => {
        const arrayToSort = [...arr];
        return arrayToSort.sort((a, b) => {
            return Number(a.institute) - Number(b.institute);
        });
    };

    const recentYear = findRecentYear(overviewTableRows);
    const overviewData = sortByStructureCode(overviewTableRows.filter(x => x.year === recentYear));

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-fim' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <div>
                {overview.permissionSet.canExportOverview ? <ExportExcelFim overviewData={overviewData} /> : <></>}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {[
                                    { name: t('fimOverview.district'), id: 1 },
                                    { name: t('fimOverview.institute'), id: 2 },
                                    { name: t('fimOverview.department'), id: 3 },
                                    { name: t('fimOverview.sdoHosps'), id: 4 },
                                    { name: t('fimOverview.fimHosps'), id: 5 },
                                    { name: t('fimOverview.difference'), id: 6 },
                                    { name: `${t('fimOverview.jan')}${recentYear}`, id: 7 },
                                    { name: `${t('fimOverview.feb')}${recentYear}`, id: 8 },
                                    { name: `${t('fimOverview.mar')}${recentYear}`, id: 9 },
                                    { name: `${t('fimOverview.apr')}${recentYear}`, id: 10 },
                                    { name: `${t('fimOverview.may')}${recentYear}`, id: 11 },
                                    { name: `${t('fimOverview.jun')}${recentYear}`, id: 12 },
                                    { name: `${t('fimOverview.jul')}${recentYear}`, id: 13 },
                                    { name: `${t('fimOverview.aug')}${recentYear}`, id: 14 },
                                    { name: `${t('fimOverview.sep')}${recentYear}`, id: 15 },
                                    { name: `${t('fimOverview.oct')}${recentYear}`, id: 16 },
                                    { name: `${t('fimOverview.nov')}${recentYear}`, id: 17 },
                                    { name: `${t('fimOverview.dec')}${recentYear}`, id: 18 },
                                ].map(item => (
                                    <th key={item.id} className={'table-dark'}>
                                        {item.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr></tr>
                            {!!overview &&
                                !isLoading &&
                                overviewData.length > 0 &&
                                overviewData.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.area}</td>
                                        <td>{data.institute}</td>
                                        <td>{data.dept}</td>
                                        <td>{data.sdoHosps.toLocaleString('de-DE')}</td>
                                        <td>{data.fimHosps.toLocaleString('de-DE')}</td>
                                        <RightTd>{data.difference.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                            {!!overview && !isLoading && overviewTableRows.length > 0 && (
                                <Fragment>
                                    <tr style={{ borderTop: '3px solid black' }}>
                                        <td style={{ fontWeight: 'bold', padding: '10px' }} colSpan={2}>
                                            {t('fimOverview.totalSdoHosps')}
                                        </td>
                                        <td style={{ fontWeight: 'bold', padding: '10px' }} colSpan={3}>
                                            {t('fimOverview.totalFimHosps')}
                                        </td>
                                        <td style={{ fontWeight: 'bold', padding: '10px' }} colSpan={3}>
                                            {t('fimOverview.totalDifferences')}
                                        </td>
                                        <td colSpan={10} />
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '10px' }} colSpan={2}>
                                            {computeTotalsAndDifference(overviewTableRows.filter(x => x.year === recentYear)).totalSdoHosps.toLocaleString('de-DE')}
                                        </td>
                                        <td style={{ padding: '10px' }} colSpan={3}>
                                            {computeTotalsAndDifference(overviewTableRows.filter(x => x.year === recentYear)).totalFimHosps.toLocaleString('de-DE')}
                                        </td>
                                        <td style={{ padding: '10px' }} colSpan={3}>
                                            {computeTotalsAndDifference(overviewTableRows.filter(x => x.year === recentYear)).totalDifference.toLocaleString('de-DE')}
                                        </td>
                                        <td colSpan={10} />
                                    </tr>
                                </Fragment>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Container>
    );
};

export default OverviewFim;
