import { DivFlexRow, FileNameContainer, FlexCenter, OverlayChild, StyledShippingInfo } from '../../style';
import { EFlowType, EShippingStatus } from '../../model/enum';
import { Fragment, useContext, useMemo, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

import CustomModal from '../Modal/CustomModal';
import CustomSvg from '../Svg/CustomSvg';
import { DetailStatus } from '../SendFilesSection/DetailStatus';
import { GenericFlowContext } from '../GenericFlow/GenericFlow';
import { IShippingDetailGeneric } from '../../model';
import { ITEMS_PER_PAGE } from '../../utils/constants';
import { IconBtn } from '../Buttons/Buttons';
import { IconButtonUnderlinedLink } from '../../style/buttons/buttons';
import InfoButton from '../Buttons/InfoButton';
import { LoggedUserContext } from '../../App';
import SogeiStatus from '../SendFilesSection/SogeiStatus';
import { downloadFile } from '../../utils';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IUploadTableFlowProps {
    permissions: any;
}

const UploadTableGenericFlow = (props: IUploadTableFlowProps) => {
    const { t } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const { permissions } = props;
    const { itemOffset, shippings, freezedShippings: allShippings, setShippings: setTotal, setFreezedShippings, flowType } = useContext(GenericFlowContext);
    const [shipping, setShipping] = useState<{ id: number | undefined; isLoading: boolean | undefined }>({ id: undefined, isLoading: undefined });
    const [show, setShow] = useState<{ confirm: boolean; cancel: boolean; shippingId: number | undefined }>({ confirm: false, cancel: false, shippingId: undefined });

    const { trigger: triggerConfirm } = useSWRMutation(`api/ExternalManagedFlow/ShippingConfirm`, httpClient.postRequest);
    const { trigger: triggerCancel } = useSWRMutation(`api/ExternalManagedFlow/CancellationRequest`, httpClient.postRequest);

    const displayDate = (fileName: string): string => {
        let parts = fileName.split('_');
        //ad hoc check for older shipping names
        let date = parts[1].length === 8 ? parts[1] : parts[2];
        const day = date.substring(0, 2);
        const month = date.substring(2, 4);
        const year = date.substring(4, 8);
        let time = parts[2];
        let hours = time.substring(0, 2);
        let minutes = time.substring(2, 4);
        let formattedTime = hours + ':' + minutes;
        return `${day}/${month}/${year} - ${formattedTime}`;
    };

    const handleConfirmShipping = (shippingId: number | undefined) => {
        toast.promise(triggerConfirm({ userId: loggedUser.userId, shippingId: shippingId, flowName: flowType }), {
            loading: t('generic.editOingoing'),
            success: response => {
                setTotal(
                    shippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.ImportRequest,
                            } as IShippingDetailGeneric;
                        }
                        return t;
                    }),
                );
                setFreezedShippings(
                    allShippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.ImportRequest,
                            } as IShippingDetailGeneric;
                        }
                        return t;
                    }),
                );
                return t('generic.success');
            },
            error: e => {
                return t('generic.error');
            },
        });
        setShow({ confirm: false, cancel: false, shippingId: undefined });
    };

    const handleCancelShipping = (shippingId: number | undefined) => {
        toast.promise(triggerCancel({ shippingId: shippingId, flowName: flowType }), {
            loading: t('generic.editOingoing'),
            success: response => {
                setTotal(
                    shippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.CancellationRequest,
                            } as IShippingDetailGeneric;
                        }
                        return t;
                    }),
                );
                setFreezedShippings(
                    allShippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.CancellationRequest,
                            } as IShippingDetailGeneric;
                        }
                        return t;
                    }),
                );
                return t('generic.success');
            },
            error: e => {
                return t('generic.error');
            },
        });
        setShow({ confirm: false, cancel: false, shippingId: undefined });
    };

    const displayUsername = (fileName: string) => {
        const segments = fileName?.split('_');
        return get(segments, '[0]', '');
    };

    const endOffset = useMemo(() => itemOffset + ITEMS_PER_PAGE, [itemOffset]);

    const currentItems = useMemo(() => {
        const orderedShippings = shippings.toSorted((a, b) => {
            const dateA = new Date(a.receiptDate).getTime();
            const dateB = new Date(b.receiptDate).getTime();
            return dateB - dateA;
        });

        const mapFromShipping = new Map(orderedShippings.map(s => [s.id, s]));
        const uniqueShippings = [...mapFromShipping.values()];

        return uniqueShippings.slice(itemOffset, endOffset);
    }, [itemOffset, endOffset, shippings]);

    return (
        <>
            {currentItems &&
                currentItems.length > 0 &&
                currentItems.map(dataShipping => (
                    <tbody key={dataShipping.id}>
                        <tr key={dataShipping.id}>
                            <td>{dataShipping.fileName1.length !== 0 ? displayDate(get(dataShipping, 'fileName1', '')) : ''}</td>
                            <td>
                                <FileNameContainer>
                                    {[dataShipping.fileName1, dataShipping.fileName2, dataShipping.fileName3, dataShipping.fileName4, dataShipping.fileName5, dataShipping.fileName6]
                                        .filter(Boolean)
                                        .map((fileName, index) => (
                                            <div key={index}>{fileName}</div>
                                        ))}
                                </FileNameContainer>
                            </td>
                            <td>
                                <StyledShippingInfo>
                                    {permissions.canViewIconFiles && dataShipping.id > 0 && (
                                        <InfoButton
                                            shippingId={dataShipping.id}
                                            filesNames={[
                                                get(dataShipping, 'fileName1', ''),
                                                get(dataShipping, 'fileName2', ''),
                                                get(dataShipping, 'fileName3', ''),
                                                get(dataShipping, 'fileName4', ''),
                                                get(dataShipping, 'fileName5', ''),
                                                get(dataShipping, 'fileName6', ''),
                                            ]}></InfoButton>
                                    )}

                                    {permissions.canDownloadFiles &&
                                    dataShipping.id > 0 &&
                                    [
                                        dataShipping.fileName1Path,
                                        dataShipping.fileName2Path,
                                        dataShipping.fileName3Path,
                                        dataShipping.fileName4Path,
                                        dataShipping.fileName5Path,
                                        dataShipping.fileName6Path,
                                    ].filter(Boolean).length > 0 ? (
                                        <OverlayTrigger
                                            placement='top'
                                            transition={false}
                                            i18nIsDynamicList
                                            overlay={
                                                <Tooltip>
                                                    <div>{get(dataShipping, 'fileName1', '')}</div>
                                                    <div>{get(dataShipping, 'fileName2', '')}</div>
                                                    <div>{get(dataShipping, 'fileName3', '')}</div>
                                                    <div>{get(dataShipping, 'fileName4', '')}</div>
                                                    <div>{get(dataShipping, 'fileName5', '')}</div>
                                                    <div>{get(dataShipping, 'fileName6', '')}</div>
                                                </Tooltip>
                                            }>
                                            <IconButtonUnderlinedLink
                                                type='button'
                                                onClick={() => {
                                                    let first = get(dataShipping, 'fileName1Path', '');
                                                    let second = get(dataShipping, 'fileName2Path', '');
                                                    let third = get(dataShipping, 'fileName3Path', '');
                                                    let fourth = get(dataShipping, 'fileName4Path', '');
                                                    let fifth = get(dataShipping, 'fileName5Path', '');
                                                    let sixth = get(dataShipping, 'fileName6Path', '');
                                                    if (!!first) downloadFile(first);
                                                    if (!!second) downloadFile(second);
                                                    if (!!third) downloadFile(third);
                                                    if (!!fourth) downloadFile(fourth);
                                                    if (!!fifth) downloadFile(fifth);
                                                    if (!!sixth) downloadFile(sixth);
                                                }}>
                                                {dataShipping.id}
                                            </IconButtonUnderlinedLink>
                                        </OverlayTrigger>
                                    ) : (
                                        <>{dataShipping.id > 0 ? dataShipping.id : ''}</>
                                    )}
                                </StyledShippingInfo>
                            </td>
                            <td>{displayUsername(dataShipping.fileName1)}</td>

                            <td>
                                {!!dataShipping.shippingStatus &&
                                    dataShipping.shippingStatus > 0 &&
                                    permissions.canDownloadFiles &&
                                    (shipping.id === dataShipping.id && shipping.isLoading ? (
                                        <div id='centeredLoadButton'>
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{get(dataShipping, 'logFileName', '')}</Tooltip>}>
                                            <OverlayChild>
                                                <IconBtn
                                                    withOutline
                                                    onClick={() => {
                                                        if (!!dataShipping.logFilePath && !shipping.isLoading) {
                                                            setShipping({ id: dataShipping.id, isLoading: true });
                                                            downloadFile(dataShipping.logFilePath);
                                                            setShipping({ id: undefined, isLoading: false });
                                                        }
                                                    }}>
                                                    <CustomSvg iconName='it-file' className='icon icon-primary' />
                                                </IconBtn>
                                            </OverlayChild>
                                        </OverlayTrigger>
                                    ))}

                                {!!dataShipping.shippingStatus &&
                                    dataShipping.shippingStatus > 0 &&
                                    permissions.canDowloadLogSynthetic &&
                                    (shipping.id === dataShipping.id && shipping.isLoading ? (
                                        <div id='centeredLoadButton'>
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{get(dataShipping, 'logFileName', '')}</Tooltip>}>
                                            <OverlayChild>
                                                <IconBtn
                                                    withOutline
                                                    onClick={() => {
                                                        if (!!dataShipping.logFilePath && !shipping.isLoading) {
                                                            setShipping({ id: dataShipping.id, isLoading: true });
                                                            downloadFile(dataShipping.logFilePath);
                                                            setShipping({ id: undefined, isLoading: false });
                                                        }
                                                    }}>
                                                    <CustomSvg iconName='it-file' className='icon icon-primary' />
                                                </IconBtn>
                                            </OverlayChild>
                                        </OverlayTrigger>
                                    ))}
                            </td>
                            <td>
                                <DetailStatus status={dataShipping.shippingStatus} isQuequed={dataShipping.queued} />
                            </td>
                            {flowType.toUpperCase() === EFlowType.Spa.toUpperCase() && (
                                <td>
                                    <DivFlexRow>
                                        {(dataShipping.shippingStatus === 7 || dataShipping.shippingStatus === 8 || dataShipping.shippingStatus === 9) && (
                                            <Fragment>
                                                {dataShipping.pdfSogeiFilePath?.length > 0 ? (
                                                    <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('genericOverview.pdfSogei')}</Tooltip>}>
                                                        <OverlayChild>
                                                            <IconBtn
                                                                withOutline
                                                                onClick={() => {
                                                                    let pdf = get(dataShipping, 'pdfSogeiFilePath', '');
                                                                    if (!!pdf) {
                                                                        downloadFile(pdf);
                                                                    }
                                                                }}>
                                                                <CustomSvg iconName='it-file-pdf-ext' className='icon icon-primary' />
                                                            </IconBtn>
                                                        </OverlayChild>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <></>
                                                )}

                                                {dataShipping.csvSogeiFilePath?.length > 0 ? (
                                                    <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('genericOverview.csvSogei')}</Tooltip>}>
                                                        <OverlayChild>
                                                            <IconBtn
                                                                withOutline
                                                                onClick={() => {
                                                                    let csv = get(dataShipping, 'csvSogeiFilePath', '');
                                                                    if (!!csv) {
                                                                        downloadFile(csv);
                                                                    }
                                                                }}>
                                                                <CustomSvg iconName='it-file-csv' className='icon icon-primary' />
                                                            </IconBtn>
                                                        </OverlayChild>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        )}
                                    </DivFlexRow>
                                </td>
                            )}
                            {flowType.toUpperCase() === EFlowType.Spa.toUpperCase() && (
                                <td>
                                    <SogeiStatus status={dataShipping.shippingStatus} />
                                </td>
                            )}
                            <td>
                                <FlexCenter style={{ gap: '1rem' }}>
                                    {!!dataShipping.shippingStatus && dataShipping.shippingStatus === EShippingStatus.CheckControlsPerformed && permissions.canConfirm && (
                                        <OverlayTrigger
                                            key={`success-${dataShipping.id}`}
                                            transition={false}
                                            i18nIsDynamicList
                                            placement='top'
                                            overlay={<Tooltip id={`success-${dataShipping.id}`}>{t(`sdoOverview.confirm`)}</Tooltip>}>
                                            <FlexCenter>
                                                <IconBtn
                                                    withOutline
                                                    borderProps={'1px solid #008055'}
                                                    onClick={() => {
                                                        setShow({ confirm: true, cancel: false, shippingId: dataShipping.id });
                                                    }}>
                                                    <CustomSvg iconName='it-check' className='icon icon-success' />
                                                </IconBtn>
                                            </FlexCenter>
                                        </OverlayTrigger>
                                    )}

                                    {(dataShipping.shippingStatus === EShippingStatus.ImportRequest || dataShipping.shippingStatus === EShippingStatus.AdminAuthorizesImport) && (
                                        <FlexCenter>{t(`sdoOverview.work`)}</FlexCenter>
                                    )}
                                    {dataShipping.shippingStatus === EShippingStatus.CancellationRequest && <FlexCenter>{t('sdoOverview.canceling')}</FlexCenter>}

                                    {!!dataShipping.shippingStatus && dataShipping.shippingStatus === EShippingStatus.CheckControlsPerformed && permissions.canDelete && (
                                        <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip id={`cancel-${dataShipping.id}`}>{t(`sdoOverview.cancel`)}</Tooltip>}>
                                            <OverlayChild>
                                                <IconBtn
                                                    withOutline
                                                    borderProps={'1px solid red'}
                                                    onClick={() => {
                                                        setShow({ confirm: false, cancel: true, shippingId: dataShipping.id });
                                                    }}>
                                                    <CustomSvg iconName='it-close' className='icon icon-danger' />
                                                </IconBtn>
                                            </OverlayChild>
                                        </OverlayTrigger>
                                    )}
                                </FlexCenter>
                            </td>
                        </tr>
                    </tbody>
                ))}
            <CustomModal
                key={`confirmModal ${show.shippingId}`}
                title={t('modals.confirmShippingTitle')}
                description={`${t('modals.confirmShipping')} ${show.shippingId} ?`}
                handleConfirm={() => handleConfirmShipping(show.shippingId)}
                show={show.confirm}
                handleClose={() => {
                    setShow({ confirm: false, cancel: false, shippingId: undefined });
                }}
            />
            <CustomModal
                key={`cancelModal ${show.shippingId}`}
                title={t('modals.deleteShippingTitle')}
                description={`${t('modals.deleteShipping')} ${show.shippingId} ?`}
                handleConfirm={() => handleCancelShipping(show.shippingId)}
                show={show.cancel}
                handleClose={() => {
                    setShow({ confirm: false, cancel: false, shippingId: undefined });
                }}
            />
        </>
    );
};

export default UploadTableGenericFlow;
